const base = require('base/product/base');
const focusHelper = require('base/components/focus');
const citrus = require('../citrus');
const tile = require('../product/tile');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

    return newUrl;
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));

    const body = $html.find('.product-quickview');
    const footer = $html.find('.modal-footer').children();

    return {
        body,
        footer
    };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    let bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    const htmlString = `${'<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="'}${data.maxBonusItems}"`
        + `data-UUID="${data.uuid}"`
        + `data-pliUUID="${data.pliUUID}"`
        + `data-addToCartUrl="${data.addToCartUrl}"`
        + 'data-pageStart="0"'
        + `data-pageSize="${data.pageSize}"`
        + `data-moreURL="${data.showProductsUrlRuleBased}"`
        + `data-bonusChoiceRuleBased="${data.bonusChoiceRuleBased}">`
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + `    <span class="">${data.labels.selectprods}</span>`
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success(response) {
            const parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        }
    });
}

/**
 * Retrieves url to use when adding a product to the cart
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl($el) {
    return $($el).closest('.add-to-cart').data('endpoint-add-to-cart');
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 * @param {jquery} el - DOM container for a given add to cart button
 */
function handlePostCartAdd(response, el) {
    $('.minicart').trigger('count:update', response);
    if (response && response.cart && response.cart.items) {
        const pid = el.closest('.add-to-cart').data('pid');
        /* eslint-disable eqeqeq */
        const products = response.cart.items.filter((item) => item.id == pid);
        /* eslint-enable eqeqeq */
        if (products && products.length > 0) {
            el.closest('.add-to-cart').find('i.added-to-cart').removeClass('d-none');
        }
        tile.updateProductAddedIcons(pid);
    }
    const messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append('<div class="add-to-cart-messages"></div>');
        }

        $('.add-to-cart-messages').append(`<div class="alert ${messageType} add-to-basket-alert text-center" role="alert">${
            response.message
        }</div>`);

        setTimeout(() => {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}


/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            const errorHtml = `${'<div class="alert alert-danger alert-dismissible valid-cart-error '
                + 'fade show" role="alert">'
                + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
                + '<span aria-hidden="true">&times;</span>'
                + '</button>'}${data.valid.message}</div>`;

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append(`${'<div class="row"> '
                + '<div class="col-12 text-center"> '
                + '<h1>'}${data.resources.emptyCartMsg}</h1> `
                + '</div> '
                + '</div>');
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * remove item from cart
 * @param {*} el - jq element of product card to be removed
 */
function removeItem(el) {
    const group = el.closest('.cartgroup');
    el.remove();
    const qty = group.find('.product-info.card').length;
    if (qty > 0) {
        group.find('.groupqty').html(qty);
    } else {
        group.find('.product-info.header').remove();
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart .minicart-total span').text(data.totals.grandTotal);
    $('.minicart .minicart-total span').data('subtotal', data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });

    if (document.getElementsByClassName('original-auth-total').length > 0) {
        const totalPriceEle = document.getElementsByClassName('original-auth-total')[0];
        const origTotalPriceString = totalPriceEle.innerText;
        const origTotalPrice = parseFloat(origTotalPriceString.substr(1));
        const saveEditBtn = document.getElementById('editOrderSaveBtn');
        const paymentEditBTn = document.getElementById('editOrderPaymentBtn');

        if (parseFloat(data.totals.grandTotal.substr(1), 10) > parseFloat(origTotalPrice, 10)) {
            saveEditBtn.style.display = 'none';
            paymentEditBTn.style.display = 'block';
        } else {
            saveEditBtn.style.display = 'block';
            paymentEditBTn.style.display = 'none';
        }
    }

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append(`- ${data.totals.orderLevelDiscountTotal.formatted}`);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append(`- ${
            data.totals.shippingLevelDiscountTotal.formatted}`);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach((item) => {
        if (item.renderedPromotions) {
            $(`.item-${item.UUID}`).empty().append(item.renderedPromotions);
        }
        if (item.priceTotal && item.priceTotal.renderedPrice) {
            $(`.item-total-${item.UUID}`).empty().append(item.priceTotal.renderedPrice);
        }
    });

    $('.groupqty').each((index, value) => {
        if (data.groupedItems) {
            const items = data.groupedItems;
            let groupExists = false;
            for (let i = 0; i < items.length; i += 1) {
                const {
                    displayName
                } = items[i];
                const {
                    groupQty
                } = items[i];
                if (this.parentNode.innerText.indexOf(displayName) !== -1) {
                    value.innerText = groupQty;
                    groupExists = true;
                    break;
                }
            }
            if (!groupExists) {
                // if there is no more group inside the cart data clear the related DOM elements
                this.parentNode.remove();
            }
        }
    });

    $('div.js-shipment-container').html(data.freeShippingHTML);

    $('.popover').find('.header-ak__summary-footer-total .grandtotal').empty().append(data.totals.grandTotal);
    $('.popover').find('.header-ak__summary-footer-element .subtotal').empty().append(data.totals.subTotal);
    $('.popover').find('.header-ak__summary-footer-element .shipping-cost').empty().append(data.totals.totalShippingCost);
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    const errorHtml = `${'<div class="alert alert-danger alert-dismissible valid-cart-error '
        + 'fade show" role="alert">'
        + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
        + '<span aria-hidden="true">&times;</span>'
        + '</button>'}${message}</div>`;

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    let html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach((item) => {
            html += `<div class="single-approaching-discount text-center">${
                item.discountMsg}</div>`;
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * re-renders the discount items
 * @param {Object} showDiscounts - updated discounts on miniCart
 */
function showDiscounts(totals) {
    const $descuentosLabel = $('.cart-discounts-label');
    const descuentosContainer = $('.cart-discounts').empty();
    const discountInfoContainer = $('<div class="cart-discounts pt-2"></div>');
    if (totals.discounts.length > 0) {
        $descuentosLabel.removeClass('u-hidden');
        $(descuentosContainer).removeClass('u-hidden');
        totals.discounts.forEach((discount) => {
            const discountInfo = $(`
                <div class="promotion-information">
                    <div class="promotion-name">${discount.lineItemText}</div>
                    <div class="applied-promotion-discount">${discount.price}</div>
                </div>
            `);
            $(discountInfoContainer).append(discountInfo);
        });
        $(descuentosContainer).append(discountInfoContainer);
    } else {
        $descuentosLabel.addClass('u-hidden');
        $(descuentosContainer).addClass('u-hidden');
    }
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    let lineItem;
    let messages = '';

    for (let i = 0; i < data.items.length; i += 1) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $(`.availability-${lineItem.UUID}`).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach((message) => {
                messages += `<p class="line-item-attributes">${message}</p>`;
            });
        }

        if (lineItem.availability.inStockDate) {
            messages += `<p class="line-item-attributes line-item-instock-date">${
                lineItem.availability.inStockDate
            }</p>`;
        }
    }

    $(`.availability-${lineItem.UUID}`).html(messages);
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (let i = 0, l = array.length; i < l; i += 1) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates the quantity of product in the cart.
 * @param {string} url - Url for handling the update
 * @param {string} uuid - UUID of the lineitem
 * @param {number} updateCartQuantityParams - params
 */
function updateCartQuantity(url, uuid, updateCartQuantityParams) {
    $(this).parents('.card').spinner().start();

    $.ajax({
        url,
        type: 'get',
        context: this,
        dataType: 'json',
        success(data) {
            // Updates selects
            $(`.quantity[data-uuid="${uuid}"]`).val(updateCartQuantityParams.quantity);
            $(this).data('pre-select-qty', updateCartQuantityParams.quantity);
            // Updates + - buttons
            $(`.add-to-cart[data-uuid="${uuid}"]`).find('.update-quantity .quantity').html(updateCartQuantityParams.quantity * updateCartQuantityParams.productConversion);
            $(`.add-to-cart[data-uuid="${uuid}"]`).data('quantity', updateCartQuantityParams.quantity);

            $('.cart-discounts.pt-2').empty().append(data.totals.discountsHtml);
            updateCartTotals(data);
            updateApproachingDiscounts(data.approachingDiscounts);
            showDiscounts(data.totals);
            updateAvailability(data, uuid);
            validateBasket(data);
            const {
                iteminfos
            } = data;
            $('.cart-json').data('cart', iteminfos);
            $('body').trigger('cart:update');

            $.spinner().stop();
            if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                // eslint-disable-next-line no-restricted-globals
                location.reload();
            }
        },
        error(err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                if (updateCartQuantityParams.previousQuantity) {
                    $(this).val(parseInt(updateCartQuantityParams.previousQuantity, 10));
                }
                $.spinner().stop();
            }
        }
    });
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    const lineItem = findItem(data.cartModel.items, (item) => item.UUID === uuid);

    if (lineItem.variationAttributes) {
        const colorAttr = findItem(lineItem.variationAttributes, (attr) => attr.attributeId === 'color');

        if (colorAttr) {
            const colorSelector = `.Color-${uuid}`;
            const newColor = `Color: ${colorAttr.displayValue}`;
            $(colorSelector).text(newColor);
        }

        const sizeAttr = findItem(lineItem.variationAttributes, (attr) => attr.attributeId === 'size');

        if (sizeAttr) {
            const sizeSelector = `.Size-${uuid}`;
            const newSize = `Size: ${sizeAttr.displayValue}`;
            $(sizeSelector).text(newSize);
        }

        const imageSelector = `.card.product-info.uuid-${uuid} .item-image > img`;
        $(imageSelector).attr('src', lineItem.images.small[0].url);
        $(imageSelector).attr('alt', lineItem.images.small[0].alt);
        $(imageSelector).attr('title', lineItem.images.small[0].title);
    }

    if (lineItem.options && lineItem.options.length) {
        const option = lineItem.options[0];
        const optSelector = `.lineItem-options-values[data-option-id="${option.optionId}"]`;
        $(optSelector).data('value-id', option.selectedValueId);
        $(`${optSelector} .line-item-attributes`).text(option.displayName);
    }

    const qtySelector = `.quantity[data-uuid="${uuid}"]`;
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $(`.remove-product[data-uuid="${uuid}"]`).data('pid', data.newProductId);

    const priceSelector = `.line-item-price-${uuid} .sales .value`;
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        const listPriceSelector = `.line-item-price-${uuid} .list .value`;
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    const htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}


/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success(data) {
            const parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    const $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    const $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

module.exports = () => {
    let selectedQuantity;
    let productConversion;
    let newPriceWeigth;

    $('body').on('click', '.remove-product, .cart-delete-confirmation-btn', function removeProduct(e) {
        e.preventDefault();
        const actionUrl = $(this).data('action');
        const productID = $(this).data('pid');
        const productName = $(this).data('name');
        const uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('change', '.allow-replacement', function f(e) {
        e.preventDefault();
        const actionUrl = $(this).data('action');
        const productID = $(this).data('pid');
        const uuid = $(this).data('uuid');
        let val = 'false';
        if ($(this).is(':checked')) {
            val = 'true';
        } else {
            val = 'false';
        }
        let urlParams;
        if (productID && uuid) {
            urlParams = {
                pid: productID,
                uuid,
                val
            };
        } else {
            urlParams = {
                val
            };
        }

        const url = appendToUrl(actionUrl, urlParams);

        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success() {
                if (uuid && val === 'false') {
                    $('#isReplacementAllowed').prop('checked', false);
                } else if (uuid && val === 'true') {
                    const checkboxes = $('input[type=checkbox][data-uuid]').length;
                    const checkedboxes = $('input[type=checkbox]:checked[data-uuid]').length;
                    if (checkboxes === checkedboxes) {
                        $('#isReplacementAllowed').prop('checked', true);
                    }
                } else if (!uuid && val === 'true') {
                    $('input[type=checkbox][data-uuid]').prop('checked', true);
                } else {
                    $('input[type=checkbox][data-uuid]').prop('checked', false);
                }
                $.spinner().stop();
            },

            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });


    $('body').on('click', '.js-delete-all', function f(e) {
        e.preventDefault();
        const actionUrl = $(this).data('action');
        const productID = $(this).data('pid');
        const productName = $(this).data('name');
        const uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', (e, data) => {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click((e) => {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function f(e) {
        e.preventDefault();

        const productID = $(this).data('pid');
        let url = $(this).data('action');
        const uuid = $(this).data('uuid');
        const urlParams = {
            pid: productID,
            uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                if (data.basket.items.length === 0) {
                    $.spinner().stop();
                    $('.cart').empty().append(`${'<div class="row"> '
                            + '<div class="col-12 text-center"> '
                            + '<h1>'}${data.basket.resources.emptyCartMsg}</h1> `
                            + '</div> '
                            + '</div>');
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });

                    $('.minicart-total span').empty().append(data.basket.totals.subTotal);
                    $('.minicart .minicart-total span').data('subtotal', data.basket.numItems);
                    $('.added-to-cart').addClass('d-none');
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (let i = 0; i < data.toBeDeletedUUIDs.length; i += 1) {
                            $(`.uuid-${data.toBeDeletedUUIDs[i]}`).remove();
                        }
                    }
                    removeItem($(`.uuid-${uuid}`));
                    $(`.uuid-${uuid}`).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    showDiscounts(data.basket.totals);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                }
                const {
                    iteminfos
                } = data;
                $('.cart-json').data('cart', iteminfos);
                tile.hideIconAddTile(`.icon-added-${productID}`);
                $('body').trigger('cart:update');

                // If after removal of productLineItem all items have been selected check the Allow Replacement button for entire basket
                const checkboxes = $('input[type=checkbox][data-uuid]').length;
                const checkedboxes = $('input[type=checkbox]:checked[data-uuid]').length;
                if (checkboxes === checkedboxes) {
                    $('#isReplacementAllowed').prop('checked', true);
                }

                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('click', '.add-to-cart button.cart-update-quantity', function f() {
        const productID = $(this).closest('.add-to-cart').data('pid');
        const currentQuantity = parseFloat($(this).closest('.add-to-cart').data('quantity'), 10).toFixed(2);
        const uuid = $(this).closest('.add-to-cart').data('uuid');
        const decrease = $(this).data('decrease') ? $(this).data('decrease') : $(this).hasClass('decrease');
        const productstepQuantity = parseFloat($(this).closest('div').find('input[name="productstepQuantity"]')[0].value);
        const targetQuantity = (decrease ? parseFloat(currentQuantity) - productstepQuantity : parseFloat(currentQuantity) + productstepQuantity);
        let url = $(this).closest('.add-to-cart').data('action');
        productConversion = $(this).closest('div').find('input[name="productConversion"]')[0].value;
        if (targetQuantity <= 0) {
            // Should use remove button to delete
            return;
        }

        const urlParams = {
            pid: productID,
            quantity: parseFloat(targetQuantity.toFixed(2)),
            uuid
        };
        url = appendToUrl(url, urlParams);
        const updateCartQuantityParams = {
            quantity: targetQuantity.toFixed(2),
            previousQuantity: currentQuantity,
            productConversion: productConversion
        };
        updateCartQuantity(url, uuid, updateCartQuantityParams);
    });

    $('.shippingMethods').change(function f() {
        const url = $(this).attr('data-actionUrl');
        const urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };

        $('.totals').spinner().start();
        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success(data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    showDiscounts(data.totals);
                    validateBasket(data);
                }
                $.spinner().stop();
            },
            error(err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit((e) => {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        const $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success(data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    showDiscounts(data.totals);
                    validateBasket(data);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('.clear-cart-form').submit((e) => {
        e.preventDefault();
        const $form = $('.clear-cart-form');
        $.spinner().start();
        $.ajax({
            url: $form.attr('action'),
            type: 'post',
            dataType: 'json',
            success: () => {
                $form.spinner().stop();
                location.reload();
            },
            error: () => {
                $form.spinner().stop();
                location.reload();
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function f(e) {
        e.preventDefault();

        const couponCode = $(this).data('code');
        const uuid = $(this).data('uuid');
        const $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        const $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function f(e) {
        e.preventDefault();

        let url = $(this).data('action');
        const uuid = $(this).data('uuid');
        const couponCode = $(this).data('code');
        const urlParams = {
            code: couponCode,
            uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                $(`.coupon-uuid-${uuid}`).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                showDiscounts(data.totals);
                validateBasket(data);
                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function f() {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success(data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error() {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', () => {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function f(e) {
        e.preventDefault();

        const editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', () => {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', () => {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', (e) => {
        const focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', (e, response) => {
        // update global add to cart (single products, bundles)
        const dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr(
            'disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', (e, response) => {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);

        const dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            const allAvailable = $('.product-availability', dialog).toArray()
                .every((item) => $(item).data('available'));

            const allReady = $('.product-availability', dialog).toArray()
                .every((item) => $(item).data('ready-to-order'));

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', (e, response) => {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function f() {
        const quantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', quantity);
    });

    $('body').on('click', '#increment, #decrement', function f() {
        const el = $(this);
        const id = el.attr('id');
        const pid = el.closest('.add-to-cart').data('pid');
        const productstepQuantity = el.parent().data('stepquantity');
        const productMinQuantity = el.parent().data('minquantity');
        selectedQuantity = el.parent().siblings('#itemquantity-' + pid).val();
        productConversion = el.parent().data('productconversion');
        if (id === 'decrement' && parseFloat(selectedQuantity, 10) > parseFloat(productMinQuantity * productConversion, 10)) {
            selectedQuantity = parseFloat(selectedQuantity, 10) - parseFloat(productstepQuantity * productConversion, 10);
            el.parent().siblings('#itemquantity-' + pid).val(parseFloat(selectedQuantity, 10));
        } else if (id === 'increment') {
            selectedQuantity = parseFloat(selectedQuantity, 10) + parseFloat(productstepQuantity * productConversion, 10);
            el.parent().siblings('#itemquantity-' + pid).val(parseFloat(selectedQuantity, 10));
        }
        $('body').trigger('wishlist:updateQuantity', { pid: pid, quantity: selectedQuantity });
        citrus.handleCictusClick(el);
    });

    selectedQuantity = $('.quantity-input-container .quantity-weight').val();
    newPriceWeigth = parseFloat(($('.quantity-input-container').data('unitprice') / productConversion * selectedQuantity).toFixed(2));
    $('#cm-item-price-weight').text(newPriceWeigth + '€');

    $(document).on('click', 'button.tile-add-to-cart', function addToCart() {
        const el = $(this);
        $('body').trigger('product:beforeAddToCart', this);

        const pid = $($(this)).closest('.add-to-cart').data('pid');
        productConversion = $($(this)).closest('.add-to-cart').data('productconversion');
        let $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
        }
        selectedQuantity = parseFloat($(this).closest('.add-to-cart').find('#itemquantity-' + pid).val(), 10);
        const addToCartUrl = getAddToCartUrl(el);

        const form = {
            pid,
            quantity: selectedQuantity / productConversion
        };

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success(data) {
                    handlePostCartAdd(data, el);
                    $('body').trigger('product:afterAddToCart', data);
                    $.spinner().stop();
                    base.miniCartReportingUrl(data.reportingURL);
                    $('.minicart-total span[data-subtotal]').text(data.cart.totals.grandTotal);
                    citrus.handleCictusClick(el);
                },
                error() {
                    $.spinner().stop();
                }
            });
        }
    });

    $('body').on('change', '.options-select', function f() {
        const selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function f(e) {
        e.preventDefault();

        const updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        const selectedQuantityItem = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        const selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        const uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        const form = {
            uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantityItem,
            selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success(data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    showDiscounts(data.cartModel.totals);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $(`.uuid-${data.uuidToBeDeleted}`).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update');

                    $.spinner().stop();
                },
                error(err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
